import axios from "axios";
import { getToken } from "../auth/msalConfig";
import UserFactory from "../factories/UserFactory";
import { stringifySearchParams } from "../utils";

const buildKilnBackendClient = async () => {
  const token = await getToken();
  return axios.create({
    baseURL: import.meta.env.VITE_KILN_BACKEND_BASEURL || "/",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus: null,
  });
};

export const getCurrentUser = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/users/current");
};

export const getUsers = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/users");
};

export const getPoeUsers = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/users/poeUserList");
};

export const getDepartedContractorUsersClass = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  const response = await kilnBackendClient.get("/api/users/departed/contractor");
  return UserFactory.createFromArray(response.data);
};

export const getManagedUsers = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  const resp = await kilnBackendClient.get("/api/users/managed");
  return UserFactory.createFromArray(resp.data);
};

export const getUsersClass = async () => {
  const response = await getUsers();
  return UserFactory.createFromArray(response.data);
};

export const checkPermission = async (type) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/forms/${type}/authority`);
};

export const checkPoePermission = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/proof-of-employment-forms/poe/authority`);
};

/************** FILES ENDPOINTS ********************/

export const uploadFile = async (file) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/files", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getDownloadLink = async (query) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return (await kilnBackendClient.get(`/api/files?${stringifySearchParams(query)}`)).data;
};

/************** DEPARTURE ENDPOINTS ********************/

export const submitDepartureForm = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/departure-forms", data);
};

export const submitEditDepartureForm = async (id, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/departure-forms/${id}`, data);
};

export const cancelDepartureForm = async (formId, cancelReason) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/departure-forms/${formId}/cancel`, {
    cancelReason,
  });
};

export const getForms = async (query) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/departure-forms?${stringifySearchParams(query)}`);
};

export const getDepartureFormByIdResponse = async (formId) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/departure-forms/${formId}`);
};

/************** PARENTAL LEAVE ENDPOINTS ********************/
export const submitParentalLeaveForm = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/parental-leave-forms", data);
};

/************** THIRD-PARTY CONTRACTOR ENDPOINTS ********************/
export const submitThirdPartyContractorForm = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/third-party-contractor-forms", data);
};

export const getThirdPartyContractorForms = async (query) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/third-party-contractor-forms?${stringifySearchParams(query)}`);
};
export const getValidThirdPartyCompanies = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/third-party-company/active-companies`);
};

export const getThirdPartyContractorExpiry = async (query) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/users/contractor-expiry?${stringifySearchParams(query)}`);
};
/************** ORGANISATION ENDPOINTS ********************/

export const getFunctions = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/org/functions");
};

export const getVerticals = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/org/verticals");
};

export const getCostCentres = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/org/cost-centres");
};

export const getProjectCostCentres = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/org/project-cost-centres");
};

export const getPositionTitles = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/org/position-titles");
};

/************** ONBOARDING ENDPOINTS ********************/

export const getOnboardingFormByLeverOpportunityId = async (opportunityId) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/onboarding-forms/lever/${opportunityId}`);
};

export const getOnboardingFormById = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/onboarding-forms/${id}`);
};

export const submitOnboardingForm = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/onboarding-forms", data);
};

export const updateOnboardingForm = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/onboarding-forms/${formId}`, data);
};

export const requestUpdateToOnboardingForm = async (formId, updateRequestReason) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post(`/api/onboarding-forms/ticket/${formId}`, { data: updateRequestReason });
};

export const getOnboardingForms = async (query) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/onboarding-forms?${stringifySearchParams(query)}`);
};

export const cancelOnboardingForm = async (formId, cancelReason) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/onboarding-forms/${formId}/cancel`, {
    cancelReason,
  });
};

export const reopenOnboardingForm = async (formId) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/onboarding-forms/${formId}/reopen`);
};

export const skipOnboardingContract = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/onboarding-forms/${formId}/skip`, data);
};

export const skipOnboardingContractAndEmploymentForms = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/onboarding-forms/${formId}/skip-form`, data);
};

export const changeOnboardingStartDate = async (formId, startDate) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/onboarding-forms/${formId}/change-start-date`, {
    data: startDate,
  });
};

export const checkPermissionForOnboarding = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/onboarding-forms/authority/${id}`);
};

/************** ROLE UPDATE ENDPOINTS ********************/

export const submitRoleUpdateForm = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/role-update-forms", data);
};

export const checkPermissionForRoleUpdate = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/role-update-forms/authority/${id}`);
};

export const getRoleUpdateFormById = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/role-update-forms/${id}`);
};

export const updateRoleUpdateForm = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/role-update-forms/${formId}`, data);
};

export const cancelRoleUpdateForm = async (formId, cancelReason) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/role-update-forms/${formId}/cancel`, {
    cancelReason,
  });
};

export const reopenRoleUpdateForm = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/role-update-forms/${id}/reopen`);
};

export const skipRoleUpdateContract = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/role-update-forms/${formId}/skip`, data);
};

export const skipRoleUpdateContractAndEmploymentForms = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/role-update-forms/${formId}/skip-form`, data);
};

export const getRoleUpdateForms = async (query) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/role-update-forms?${stringifySearchParams(query)}`);
};

export const getRoleUpdateFormByLeverOpportunityId = async (opportunityId) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/role-update-forms/lever/${opportunityId}`);
};

export const updateApprovalLevel = async (formId, levelApproval) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/role-update-forms/${formId}/level-approval`, levelApproval);
};

/* ********** END-of-YEAR RATINGS ENDPOINTS ************** */

export const submitEoyRatings = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/eoy-ratings/", data);
};

export const getRatingsCsvForYear = async (year) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/eoy-ratings/${year}`);
};

export const getManagerRatingsCsvForYear = async (year, type) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/eoy-ratings/manager/${type}/${year}`);
};

export const getEoyRatingsForm = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/eoy-ratings/");
};

export const checkIfFormIsOpen = async () => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get("/api/eoy-ratings/check/form-open");
};

export const updateEoyRatingsForm = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/eoy-ratings/${formId}`, data);
};

/* ********** TALENT REVIEW ENDPOINTS ************** */

export const submitTalentReview = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/talent-review/", data);
};

export const getManagerTalentReviewCsvForYear = async (year) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/talent-review/manager/${year}`);
};

export const checkManagerTalentReviewForYear = async (year) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/talent-review/manager/check/${year}`);
};

/************** PROOF OF EMPLOYMENT ENDPOINTS ********************/

export const getProofOfEmploymentFormById = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/proof-of-employment-forms/${id}`);
};

export const submitProofOfEmploymentForm = async (data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.post("/api/proof-of-employment-forms", data);
};

export const updateProofOfEmploymentForm = async (formId, data) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.patch(`/api/proof-of-employment-forms/${formId}`, data);
};

export const checkPermissionForProofOfEmploymentUpdate = async (id) => {
  const kilnBackendClient = await buildKilnBackendClient();
  return await kilnBackendClient.get(`/api/proof-of-employment-forms/authority/${id}`);
};
